<template>
  <div class="side-mine">
    <div class="side-mine-item" @click="jump('subject')">
      <div class="label">
        <div class="icon">
          <img
            src="@/assets/image/bbs/mine_4.png"
            :alt="$t('bbs.my.publish')"
          />
        </div>
        <div class="text">{{ $t("bbs.my.publish") }}</div>
        <!-- 我发布的 -->
      </div>
    </div>
    <div class="side-mine-item" @click="jump('draft')">
      <div class="label">
        <div class="icon">
          <img src="@/assets/image/bbs/mine_5.png" :alt="$t('bbs.my.draft')" />
        </div>
        <div class="text">{{ $t("bbs.my.draft") }}</div>
        <!-- 我的草稿 -->
      </div>
    </div>
    <div class="side-mine-item" @click="jump('follow')">
      <div class="label">
        <div class="icon">
          <img src="@/assets/image/bbs/mine_6.png" :alt="$t('bbs.my.follow')" />
        </div>
        <div class="text">{{ $t("bbs.my.follow") }}</div>
        <!-- 我关注的 -->
      </div>
    </div>
    <div class="side-mine-item" @click="jump('collection')">
      <div class="label">
        <div class="icon">
          <img
            src="@/assets/image/bbs/mine_7.png"
            :alt="$t('bbs.my.collect')"
          />
        </div>
        <div class="text">{{ $t("bbs.my.collect") }}</div>
        <!-- 我收藏的 -->
      </div>
    </div>
    <div class="side-mine-item" @click="jump('comment')">
      <div class="label">
        <div class="icon">
          <img
            src="@/assets/image/bbs/mine_1.png"
            :alt="$t('bbs.my.comment')"
          />
        </div>
        <div class="text">{{ $t("bbs.my.comment") }}</div>
        <!-- 评论我的 -->
      </div>
      <div class="value" v-if="comments">{{ comments }}</div>
    </div>
    <div class="side-mine-item" @click="jump('answer')">
      <div class="label">
        <div class="icon">
          <img src="@/assets/image/bbs/mine_2.png" :alt="$t('bbs.my.answer')" />
        </div>
        <div class="text">{{ $t("bbs.my.answer") }}</div>
        <!-- 回答我的 -->
      </div>
      <div class="value" v-if="answers">{{ answers }}</div>
    </div>
    <div class="side-mine-item" @click="jump('like')">
      <div class="label">
        <div class="icon">
          <img src="@/assets/image/bbs/mine_3.png" :alt="$t('bbs.my.like')" />
        </div>
        <div class="text">{{ $t("bbs.my.like") }}</div>
        <!-- 点赞我的 -->
      </div>
      <div class="value" v-if="likes">{{ likes }}</div>
    </div>
    <div class="side-mine-item" @click="jump('invitation')">
      <div class="label">
        <div class="icon">
          <img
            src="@/assets/image/bbs/mine_8.png"
            :alt="$t('bbs.my.invited')"
          />
        </div>
        <div class="text">{{ $t("bbs.my.invited") }}</div>
        <!-- 邀请我的 -->
      </div>
      <div class="value" v-if="invites">{{ invites }}</div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import { useRouter } from "vue-router";
import { getMsgCount } from "@/api/bbs";
export default {
  name: "sideMine",
  props: {
    newWindow: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const router = useRouter();
    const state = reactive({
      comments: 0,
      answers: 0,
      likes: 0,
      invites: 0,
    });

    getMsgCount().then((res) => {
      state.comments = res.data.comments || 0;
      state.answers = res.data.answers || 0;
      state.likes = res.data.likes || 0;
      state.invites = res.data.invites || 0;
    });

    const jump = (mod) => {
      if (props.newWindow) {
        window.open(`/bbs/info#${mod}?ddtab=true`);
      } else {
        router.push(`/bbs/info#${mod}`);
      }
    };

    return {
      ...toRefs(state),
      jump,
    };
  },
};
</script>

<style lang="less" scoped>
.side-mine {
  padding: 15px 22px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 15px 0px rgba(148, 148, 148, 0.15);
  &-item {
    height: 28px;
    margin-bottom: 12px;
    .mixinFlex(space-between);
    cursor: pointer;
    &:last-child {
      margin-bottom: 0;
    }
    &:hover {
      .label {
        color: @color-theme;
      }
    }
    .label {
      line-height: 28px;
      font-size: 14px;
      color: #7c879d;
      transition: color 0.3s;
      .mixinFlex(flex-start; center);
      .icon {
        width: 16px;
        height: 16px;
        margin-right: 10px;
        .mixinFlex(center; center);
      }
    }
    .value {
      min-width: 28px;
      height: 28px;
      padding: 0 5px;
      border-radius: 2px;
      background-color: #f5f5f5;
      color: #828ca1;
      .mixinFlex(center; center);
      position: relative;
      &::before {
        content: "";
        width: 8px;
        height: 8px;
        background-color: #f5222d;
        border-radius: 50%;
        position: absolute;
        top: -3px;
        right: -3px;
      }
    }
  }
}
</style>
